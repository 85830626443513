






























import {Vue, Component, Prop} from 'vue-property-decorator'
import {
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BButton, BCol, BRow, BCard, BInputGroup, BCardTitle
} from "bootstrap-vue";
import {VueGoodTable} from 'vue-good-table'
import {NotifyInfo, NotifySuccess} from "@/helpers/Toast";

@Component({
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardTitle
  }
})
export default class AutoOnboardings extends Vue {
  columns = [
    {
      label: 'Name',
      field: 'name',
    },
    {
      label: 'Vacancy Reference Ids',
      field: 'vacancyReferenceIds',
    },
  ]

  rows: RegistrationsAPI.AutoOnboardings.AutoOnboarding[] = [];

  async created() {
    await this.getItems();
  }

  async getItems() {
    this.rows = await this.$api.registrations.autoonboarding.GetList();
  }

  async goTo(params) {
    await this.$router.push({
      name: "registration-autoonboarding",
      params: {
        identifier: params.row.id
      }
    })
  }

  async create() {
    await this.$router.push({
      name: "registration-autoonboarding",
      params: {
        identifier: "create"
      }
    })
  }
}
